import classNames from "classnames";
import React, { useEffect, useState } from "react";
import axios from "../../components/_axios";
import { Link, NavLink } from "react-router-dom";


const IntensityTag = ({intensity}) => {
  let color = "#ffffff";
  if (intensity < 0.5) color = "#ffffff";
  else if (intensity < 1.5) color = "#e1e1e1";
  else if (intensity < 2.5) color = "#b9ebe1";
  else if (intensity < 3.5) color = "#91c8e6";
  else if (intensity < 4.5) color = "#f0f0af";
  else if (intensity < 5.5) color = "#fff04b";
  else if (intensity < 6.5) color = "#ffa819";
  else if (intensity < 7.5) color = "#ff3c3c";
  else if (intensity < 8.5) color = "#c80f0f";
  else if (intensity < 9.5) color = "#d70064";
  else if (intensity < 10.5) color = "#801969";
  else color = "#404040";
  let fontColor;
  if (intensity > 6.5) fontColor = "#ffffff";
  else fontColor = "#252525";
  return <span
    style={{
      backgroundColor: color,
      padding: "6px 12px",
      borderRadius: "4px",
      fontWeight: 600,
      color: fontColor
    }}
  >{intensity}</span>;
}

const round = (intensity) => Math.floor(intensity + 0.5);


export default function EarthquakeList() {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [events, setEvents] = useState([]);
  const PAGESIZE = 10;

  const fetchPage = page => axios.get(
    "/action/weather/earthquake",
    { params: { pagesize: PAGESIZE, page } }
  ).then(res => {
    setEvents(res.data.events);
    setTotalPage(Math.ceil(res.data.count / PAGESIZE));
  });

  useEffect(() => {fetchPage(page);}, [page]);

  return <>
    <table className="table is-fullwidth is-striped is-hoverable table-container" style={{fontFamily: "Lato"}}>
      <thead>
        <tr>
          <th>发震时间</th>
          <th>震级</th>
          <th>最大烈度</th>
          <th>经纬度</th>
          <th>震中</th>
        </tr>
      </thead>
      <tbody>
        {events.map(event => <tr>
          <td style={{cursor:"pointer"}}>
            <Link to={`/earthquake/${event.id}/`} >
              <i className='fas fa-search-plus' /> {event.time}
            </Link>
          </td>
          <td>M {event.magnitude.toFixed(1)}</td>
          <td><IntensityTag intensity={round(event.max_intensity)} /></td>
          <td>{`${event.latitude}N ${event.longitude}E`}</td>
          <td>{event.location}</td>
        </tr>)}
      </tbody>
      <tfoot>
        <tr>
          <th>发震时间</th>
          <th>震级</th>
          <th>最大烈度</th>
          <th>经纬度</th>
          <th>震中</th>
        </tr>
      </tfoot>
    </table>
    <nav class="pagination" role="navigation" aria-label="pagination">
      <a onClick={() => {if (page > 1) setPage(page - 1);}} class={classNames({
        "pagination-previous": true,
        "is-disabled": page === 1
      })}>上一页</a>
      <a onClick={() => {if (page < totalPage) setPage(page + 1);}} class={classNames({
        "pagination-next": true,
        "is-disabled": page === totalPage
      })}>下一页</a>
      <ul class="pagination-list">
        <li>
          <a onClick={() => setPage(1)} class={classNames({
            "pagination-link": true,
            "is-current": page === 1
          })}>1</a>
        </li>
        { page > 3 && <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li> }
        { page > 2 && <li>
          <a onClick={() => setPage(page - 1)} class="pagination-link">{page - 1}</a>
        </li>}
        { page > 1 && <li>
          <a class="pagination-link is-current">{page}</a>
        </li> }
        { page < totalPage - 1 && <li>
          <a onClick={() => setPage(page + 1)} class="pagination-link">{page + 1}</a>
        </li>}
        { page < totalPage - 2 && <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li>}
        { page < totalPage && <li>
          <a onClick={() => setPage(totalPage)} class="pagination-link">{totalPage}</a>
        </li>}
      </ul>
    </nav>
  </>

}


export function EarthquakeDetail ({eventid}) {
  const [detail, setDetail] = useState({});
  const [previous, setPrevious] = useState("");
  const [next, setNext] = useState("");

  useEffect(() => {
    axios.get(
      "/action/weather/earthquake",
      { params: {eventid} }
    ).then(res => {
      setDetail(res.data.event);
      setPrevious(res.data.previous);
      setNext(res.data.next);
    });
  }, [ eventid ]);

  if (!detail.id) return null;

  return <div>
    <nav className="panel is-light">
      <p className="panel-heading">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <p className="subtitle is-5">基本信息</p>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {previous && <NavLink to={`/earthquake/${previous}/`} >
                <button className="button">上一条</button>
              </NavLink>}
            </div>
            <div className="level-item">
              {next && <NavLink to={`/earthquake/${next}/`}>
                <button className="button">下一条</button>
              </NavLink>}
            </div>
          </div>
        </nav>
      </p>
      <div className="panel-block">
        <nav className="columns is-centered">
          <div className="column has-text-centered">
            <div>
              <p className="heading">时间</p>
              <p className="subtitle" style={{fontFamily:"Lato"}}>
                {detail.time}
              </p>
            </div>
          </div>
          <div className="column has-text-centered">
            <div>
              <p className="heading">震级</p>
              <p className="subtitle" style={{fontFamily:"Lato"}}>M {detail.magnitude.toFixed(1)}</p>
            </div>
          </div>
          <div className="column has-text-centered">
            <div>
              <p className="heading">最大烈度</p>
              <p className="subtitle" style={{fontFamily:"Lato", padding:"10px 0"}}>
                <IntensityTag intensity={detail.max_intensity} />
              </p>
            </div>
          </div>
          <div className="column has-text-centered">
            <div>
              <p className="heading">经纬度</p>
              <p className="subtitle" style={{fontFamily:"Lato"}}>
                {`${detail.latitude.toFixed(2)}N ${detail.longitude.toFixed(2)}E`}
              </p>
            </div>
          </div>
          <div className="column has-text-centered">
            <div>
              <p className="heading">位置</p>
              <p className="subtitle" style={{fontFamily:"Lato"}}>
                {detail.location}
              </p>
            </div>
          </div>
        </nav>
      </div>
    </nav>
    <nav className="panel is-light">
      <p className="panel-heading">
        <p className="subtitle">烈度报告</p>
      </p>
      <div className="panel-block">
        <div className="content" style={{whiteSpace:"pre-wrap"}}>
          {detail.region_summary}
        </div>
      </div>
      <div className="panel-block">
        <figure className="image" style={{maxWidth:"720px"}}>
          <img src={`/media/quake/${eventid}R.png`} />
        </figure>
      </div>
      <div className="panel-block">
        <figure className="image" style={{maxWidth:"720px"}}>
          <img src={`/media/quake/${eventid}S.png`} />
        </figure>
      </div>
    </nav>
  </div>
}
